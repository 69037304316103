import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { getManufacturerURL, getSlug } from '../utils/url'

const ManufacturerList = () => {
	const data = useStaticQuery(graphql`
		query ManufacturersList {
			allManufacturer: allSanityManufacturer {
				edges {
					node {
						title
						slug {
							current
						}
						image {
							asset {
								gatsbyImageData(width: 256, layout: CONSTRAINED)
							}
						}
					}
				}
			}
		}
	`)

	return (
		<Layout>
			<SEO title="Fabricantes" />
			<h1 className="uk-heading-line">Fabricantes</h1>

			<div
				className="uk-grid-small uk-child-width-1-3 uk-flex-center uk-flex-middle uk-text-center"
				data-uk-grid
			>
				{data.allManufacturer.edges.map(({ node }) => (
					<div key={getSlug(node)}>
						<Link to={getManufacturerURL(getSlug(node))}>
							{
								<GatsbyImage
									image={getImage(node.image.asset)}
									imgStyle={{ maxWidth: 256, objectFit: 'contain' }}
								/>
							}
						</Link>
					</div>
				))}
			</div>
		</Layout>
	)
}

export default ManufacturerList
